import { useEffect, useState } from "react";

interface UseInViewIdArgs {
  elements: Array<HTMLElement | undefined>;
}

export function useInViewId({ elements }: UseInViewIdArgs) {
  const [inViewId, setInViewId] = useState<string | undefined>();

  useEffect(() => {
    const inViewSet = new Map<string, HTMLElement>();

    const callback: IntersectionObserverCallback = (changes) => {
      changes.forEach((change) => {
        if (change.isIntersecting) {
          inViewSet.set(change.target.id, change.target as HTMLElement);
        } else {
          inViewSet.delete(change.target.id);
        }
      });

      const inView = Array.from(inViewSet.entries())
        .map(([id, el]) => {
          return [id, el.offsetTop] as const;
        })
        .filter(([id, _]) => {
          return !!id;
        });

      if (inView.length > 0) {
        setInViewId(
          inView.reduce((acc, next) => {
            return next[1] < acc[1] ? next : acc;
          })[0]
        );
      }
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: "0px 0px -20% 0px",
    });

    elements.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      return observer.disconnect();
    };
  }, [elements]);

  return inViewId;
}
