import React from "react";

import * as styles from "./styles.css";

export interface VideoYoutubeProps {
  embedId: string;
}

export function VideoYoutube({ embedId }: VideoYoutubeProps) {
  return (
    <div className={styles.wrapper}>
      <iframe
        className={styles.iframe}
        width="560"
        height="315"
        src={`https://www.youtube-nocookie.com/embed/${embedId}?rel=0`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </div>
  );
}
