import React from "react";

import { Box } from "../Box";

import type { BoxProps } from "../Box";

export function VStack({ children, gap = "spacing3", ...rest }: BoxProps) {
  return (
    <Box display="flex" flexDirection="column" gap={gap} {...rest}>
      {children}
    </Box>
  );
}
