import { createRef, useCallback, useState } from "react";

interface UseVideoPlayingStateArgs {
  hasControls?: boolean;
}

export function useVideoPlayingState({
  hasControls = false,
}: UseVideoPlayingStateArgs) {
  const [isPlaying, setIsPlaying] = useState<boolean>(!hasControls);
  const videoRef = createRef<HTMLVideoElement>();

  const toggleIsPlaying = useCallback(() => {
    if (videoRef.current) {
      if (!isPlaying) {
        videoRef.current.play().then(() => {
          setIsPlaying(true);
        });
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, [isPlaying, videoRef]);

  const onPlay = useCallback(() => {
    return setIsPlaying(true);
  }, [setIsPlaying]);

  const onPause = useCallback(() => {
    return setIsPlaying(false);
  }, [setIsPlaying]);

  return { isPlaying, toggleIsPlaying, onPlay, onPause, videoRef };
}
