import React from "react";

import { AnchorJsContext } from "~context/AnchorJsContext";

import { ContentsLink } from "./_ContentsLink";
import { TableOfContentsAccordionItem } from "./_TableOfContentsAccordionItem";

import type { HeadingObject } from "./_transformAnchorsToTableOfContents";

export function TableOfContentsItem(
  { childHeadings, id, link, title }: HeadingObject,
  index: number
) {
  const { activeId } = React.useContext(AnchorJsContext) || {};

  if (Array.isArray(childHeadings) && childHeadings.length > 0) {
    return (
      <TableOfContentsAccordionItem
        activeId={activeId}
        index={index}
        title={title}
        link={link}
        childHeadings={childHeadings}
        id={id}
      />
    );
  }

  if (!link) return null;

  return (
    <ContentsLink link={link} isActive={id === activeId}>
      {title}
    </ContentsLink>
  );
}
