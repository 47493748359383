/**
 *  * This function is imported in both `gatsby-ssr.tsx` and `gatsby-browser.tsx`
 * `wrapPageElement` is a Gatsby API exposed both during server-side rendering & in the client.
 * It wraps the entire app, is unmounted on a route change, and receives similar props to the page
 * component, so it's a good place to wrap the app with context providers, or other global components.
 *
 * - https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement
 * - https://www.gatsbyjs.com/docs/reference/config-files/gatsby-ssr/#wrapPageElement
 */
import React from "react";

import { Provider as ReactBalancerProvider } from "react-wrap-balancer";

import { PageQueryContextProvider } from "~context/PageQueryContext";

import type {
  GatsbyBrowser,
  GatsbySSR,
  WrapPageElementBrowserArgs,
  WrapPageElementNodeArgs,
} from "gatsby";
import type { GlobalPageData } from "~types/global.types";
import type { CommonPageContext } from "~types/storyblok.types";

export type WrapPageElement = GatsbyBrowser["wrapPageElement"] &
  GatsbySSR["wrapPageElement"];

export const wrapPageElement = ({
  element,
  props,
}: WrapPageElementBrowserArgs<GlobalPageData, CommonPageContext> &
  WrapPageElementNodeArgs<GlobalPageData, CommonPageContext>) => {
  const { data, pageContext } = props || {};

  return (
    <PageQueryContextProvider pageContext={pageContext} data={data}>
      <ReactBalancerProvider>{element}</ReactBalancerProvider>
    </PageQueryContextProvider>
  );
};
