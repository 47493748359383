import React, { createContext, useMemo } from "react";

import type { ReactNode } from "react";
import type { EnabledLanguageIsoCode } from "~config/ENABLED_LANGUAGES.config";
import type { GlobalPageData } from "~types/global.types";
import type {
  ContentTopic,
  SbRelationsGraphQlNode,
  StoryblokEntryWithStringifiedContent,
} from "~types/storyblok.types";

export interface PageQueryContextProviderProps {
  children: ReactNode;
  data?: GlobalPageData & {
    author?: StoryblokEntryWithStringifiedContent;
    relationsCardsNodes?: {
      nodes?: Array<SbRelationsGraphQlNode>;
    };
    relationsTagsNodes?: {
      nodes?: Array<SbRelationsGraphQlNode>;
    };
    relationsRecommendedContentNodes?: {
      nodes?: Array<SbRelationsGraphQlNode>;
    };
    relationsLinkListNodes?: {
      nodes?: Array<SbRelationsGraphQlNode>;
    };
    paginationItems?: {
      nodes?: Array<SbRelationsGraphQlNode>;
    };
    tagsItems?: {
      nodes?: Array<SbRelationsGraphQlNode>;
    };
  };
  pageContext?: {
    lang?: EnabledLanguageIsoCode;
    paginationIds?: Array<string> | null;
    storyblokEntryParentSlug?: string;
    topics?: Array<ContentTopic>;
  };
}

type PageQueryContextProvided = Pick<
  PageQueryContextProviderProps,
  "data" | "pageContext"
>;

const PageQueryContext = createContext<PageQueryContextProvided>({});

function PageQueryContextProvider({
  children,
  data,
  pageContext,
}: PageQueryContextProviderProps) {
  const value = useMemo(() => {
    return {
      data,
      pageContext,
    };
  }, [data, pageContext]);

  return (
    <PageQueryContext.Provider value={value}>
      {children}
    </PageQueryContext.Provider>
  );
}

export { PageQueryContext, PageQueryContextProvider };
