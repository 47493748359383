/**
 *  * This function is imported in both `gatsby-ssr.tsx` and `gatsby-browser.tsx`
 * `wrapRootElement` is a Gatsby API exposed both during server-side rendering & in the client.
 * It wraps the entire app, and isn't unmounted on route change, so it's a good place to import  globals.
 *
 * - https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapRootElement
 * - https://www.gatsbyjs.com/docs/reference/config-files/gatsby-ssr/#wrapRootElement
 */
import "@fortawesome/fontawesome-svg-core/styles.css";
import "pure-react-carousel/dist/react-carousel.es.css";

import "~styles/global.css";

import type { ReactNode } from "react";

interface wrapRootElementProps {
  element?: ReactNode | Array<ReactNode>;
}

export const wrapRootElement = ({ element }: wrapRootElementProps) => {
  return element;
};
