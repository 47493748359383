import type { AxisScrollInfo } from "motion";

export interface SectionScrollableStateShape {
  isUserInteracting?: boolean;
  accordionLength: number;
  activeIndex?: number;
  previousIndex?: number;
}

export interface SectionScrollableStateActionPayload {
  y?: AxisScrollInfo;
  newIndex?: number;
}

export interface SectionScrollableStateAction {
  payload?: SectionScrollableStateActionPayload;
  type?: "HANDLE_SCROLL" | "HANDLE_SET_INDEX";
}

export type SectionScrollReducer = (
  state: SectionScrollableStateShape,
  action: SectionScrollableStateAction
) => SectionScrollableStateShape;

export const sectionScrollReducer: SectionScrollReducer = (state, action) => {
  const { accordionLength, activeIndex } = state || {};

  const { y, newIndex } = action.payload || {};

  const computedActiveIndex =
    y && accordionLength ? Math.floor(y.progress * accordionLength) : undefined;

  switch (action.type) {
    case "HANDLE_SCROLL":
      /** If value hasn't changed, do nothing */
      if (
        typeof activeIndex === "number" &&
        typeof computedActiveIndex === "number" &&
        activeIndex === computedActiveIndex
      ) {
        return state;
      }

      return {
        ...state,
        activeIndex: computedActiveIndex,
        previousIndex: activeIndex,
      };

    case "HANDLE_SET_INDEX":
      return {
        ...state,
        isUserInteracting: true,
        activeIndex: newIndex,
        previousIndex: activeIndex,
      };

    default: {
      return {
        ...state,
      };
    }
  }
};
