import React from "react";

import { useMatchMedia } from "~hooks/use_match_media/use_match_media";

import { smoothScrollToElement } from "~utils/link_utils/smoothScrollToElement";

import { AccordionBaseItem } from "~components/AccordionBase/AccordionBaseItem";
import { AccordionBaseItemContent } from "~components/AccordionBase/AccordionBaseItemContent";
import { AccordionBaseItemTrigger } from "~components/AccordionBase/AccordionBaseItemTrigger";
import { Link } from "~components/Link";

import { ContentsLink } from "./_ContentsLink";
import { linkStyle } from "./styles.css";

import type { HeadingObject } from "./_transformAnchorsToTableOfContents";
import type { AnchorJsContextProvided } from "~context/AnchorJsContext";

interface TableOfContentsAccordionItemProps extends HeadingObject {
  activeId: AnchorJsContextProvided["activeId"];
  index: number;
}

export function TableOfContentsAccordionItem({
  childHeadings,

  id,
  link,
  index,
  title,
  activeId,
}: TableOfContentsAccordionItemProps) {
  const handleSmoothScroll = () => {
    const to = link?.story?.url;
    if (!to) return;
    smoothScrollToElement(to);
  };

  const isViewportDesktop = useMatchMedia({ min: "DESKTOP" });

  return (
    <AccordionBaseItem index={index} value={id}>
      <AccordionBaseItemTrigger onClick={handleSmoothScroll} asChild>
        <Link link={link} className={linkStyle}>
          {title}
        </Link>
      </AccordionBaseItemTrigger>

      {isViewportDesktop && (
        <AccordionBaseItemContent>
          {childHeadings.map((subHeading) => {
            const { id: subId } = subHeading || {};

            if (subHeading.title && subHeading.link) {
              return (
                <ContentsLink
                  marginLeft="spacing3"
                  link={subHeading.link}
                  isActive={subId === activeId}
                >
                  {subHeading.title}
                </ContentsLink>
              );
            }

            return null;
          })}
        </AccordionBaseItemContent>
      )}
    </AccordionBaseItem>
  );
}
