import React, { createContext, useContext, useMemo } from "react";

import { useClearRefinements } from "react-instantsearch";

import { useVirtualRefinementList } from "~sections/AlgoliaSearch/useVirtualRefinementList";

import type { ClearRefinementsRenderState } from "instantsearch.js/es/connectors/clear-refinements/connectClearRefinements";
import type { RefinementListRenderState } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
import type { ReactNode } from "react";

const AVAILABLE_REFINEMENTS = {
  CONTENT_TYPE: "contentType",
  LANG: "lang",
  TOPICS: "topics",
} as const;

export interface ContentSearchContextProvided {
  langRefinementListProps?: RefinementListRenderState;
  clearLangRefinementList: ClearRefinementsRenderState["refine"];
  contentTypeRefinementListProps?: RefinementListRenderState;
  clearContentTypeRefinementList: ClearRefinementsRenderState["refine"];
  topicsRefinementListProps?: RefinementListRenderState;
  clearTopicsRefinementList: ClearRefinementsRenderState["refine"];
}

export interface ContentSearchContextProviderProps {
  children: ReactNode;
}

const ContentSearchContext = createContext<ContentSearchContextProvided | null>(
  null
);

export function useContentSearchContext() {
  const context = useContext(ContentSearchContext);

  if (!context) {
    throw new Error(
      "useContentSearchContext used outside of a ContentSearchContextProvider"
    );
  }

  return context;
}

export function ContentSearchContextProvider({
  children,
}: ContentSearchContextProviderProps) {
  /**
   * INSTANTIATE "VIRTUAL" REFINEMENT LISTS
   *
   * Because we may be mounting/unmounting refinement lists in a dialog,
   * the refinement state will be lost on an unmount. As a workaround,
   * we invoke the `useRefinementList` hook outside the scope of the refinement list
   * UI component, destructure the API methods, and pass those to the components via a context provoker.
   *
   * This approach recommended by React InstantSearch maintainer here:
   * https://github.com/algolia/react-instantsearch/issues/3542#issuecomment-1170086928
   */

  const langRefinementListProps = useVirtualRefinementList({
    attribute: "lang",
    limit: 999,
  });

  const contentTypeRefinementListProps = useVirtualRefinementList({
    attribute: "contentType",
    limit: 999,
  });

  const topicsRefinementListProps = useVirtualRefinementList({
    attribute: "topics",
    limit: 999,
  });

  const { refine: clearLangRefinementList } = useClearRefinements({
    includedAttributes: [AVAILABLE_REFINEMENTS.LANG],
  });

  const { refine: clearContentTypeRefinementList } = useClearRefinements({
    includedAttributes: [AVAILABLE_REFINEMENTS.CONTENT_TYPE],
  });

  const { refine: clearTopicsRefinementList } = useClearRefinements({
    includedAttributes: [AVAILABLE_REFINEMENTS.TOPICS],
  });

  const value = useMemo(() => {
    return {
      langRefinementListProps,
      clearLangRefinementList,
      contentTypeRefinementListProps,
      clearContentTypeRefinementList,
      topicsRefinementListProps,
      clearTopicsRefinementList,
    };
  }, [
    clearLangRefinementList,
    langRefinementListProps,
    clearContentTypeRefinementList,
    contentTypeRefinementListProps,
    topicsRefinementListProps,
    clearTopicsRefinementList,
  ]);

  return (
    <ContentSearchContext.Provider value={value}>
      {children}
    </ContentSearchContext.Provider>
  );
}
