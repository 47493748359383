import React, { useEffect, useState } from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";
import { getGatsbyImage } from "gatsby-plugin-storyblok-image";

import { focusRingStyles } from "~styles/common/a11y.focus.css";
import { useResponsiveFieldValue } from "~styles/css_runtime_utils/useResponsiveFieldValue";
import { getSprinkles } from "~styles/getSprinkles.css";

import { useIntersect } from "~hooks/useIntersect";
import { useMatchMedia } from "~hooks/use_match_media/use_match_media";

import { Box } from "../Box";
import { VideoPlayButton } from "../VideoPlayButton";
import { useVideoPlayingState } from "./_useVideoPlayingState";

import type { BoxProps } from "../Box";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { StoryblokFieldMedia } from "~types/storyblok.types";

export interface VideoProps extends Omit<BoxProps, "poster"> {
  caption?: string;
  hasBorderRadius?: boolean;
  hasControls?: boolean;
  // hasShadow?: boolean;
  poster?: StoryblokFieldMedia;
  video?: StoryblokFieldMedia;
  videoClassName?: string;
  isHiddenOnMobile?: boolean;
}

/** Renders a html5 video player, and loads a video. supports optional controls and several styling props */
export function Video({
  caption,
  display,
  hasBorderRadius,
  hasControls,
  className: userClassName,
  height,
  position = "relative",
  poster,
  preload,
  video,
  videoClassName,
  isHiddenOnMobile,
  width = "100%",
  ...rest
}: VideoProps) {
  const isViewportMobile = useMatchMedia({ max: "MOBILE" });

  const shouldHideVideo = isViewportMobile && isHiddenOnMobile;

  // const isReduceMotionPreferred =
  //   typeof window !== "undefined" &&
  //   window.matchMedia("(prefers-reduced-motion: reduce)").matches;

  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  const validResponsiveDisplayProps =
    useResponsiveFieldValue<GetSprinklesArgs["display"]>(display);

  const { filename } = video || {};

  const { isPlaying, toggleIsPlaying, onPlay, onPause, videoRef } =
    useVideoPlayingState({
      hasControls,
    });

  const [observedElRef, IOEntry] = useIntersect({
    rootMargin: "0% 0% 0% 0%",
  });

  const isInView = IOEntry?.isIntersecting;

  const [lazyVideoSrc, setLazyVideoSrc] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (!shouldHideVideo && isInView && filename) {
      setLazyVideoSrc(filename);
    }
  }, [filename, isInView, shouldHideVideo]);

  const videoClassNames = clsx(
    videoClassName,
    focusRingStyles,

    getSprinkles({
      borderRadius: hasBorderRadius ? "md" : undefined,
      height,
      maxWidth: "100%",
      objectFit: "cover",
      width,
    })
  );

  const videoPoster =
    (poster?.filename &&
      getGatsbyImage(poster.filename, {
        layout: "constrained",
      })?.images.fallback?.src) ||
    undefined;

  return (
    <Box
      ref={observedElRef}
      className={userClassName}
      as={caption ? "figure" : "div"}
      data-testid="video-wrapper"
      display={shouldHideVideo ? "none" : validResponsiveDisplayProps}
      height="auto"
      maxWidth="100%"
      position={position} // <-- ensure play button is able to position itself relative to the video
      width="100%"
      {...atomProps}
    >
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        autoPlay={!hasControls}
        className={videoClassNames}
        controls={hasControls}
        controlsList="nodownload"
        loop
        muted={!hasControls}
        onPause={onPause}
        onPlaying={onPlay}
        playsInline={!hasControls}
        poster={videoPoster}
        preload={preload || "none"}
        ref={videoRef}
        src={lazyVideoSrc}
        {...otherProps}
      />
      {hasControls && (
        <VideoPlayButton isPlaying={isPlaying} onClick={toggleIsPlaying} />
      )}
      {!!caption && (
        <Box as="figcaption" textAppearance="caption">
          {caption}
        </Box>
      )}
    </Box>
  );
}
