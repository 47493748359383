import React, { createContext, useContext, useMemo } from "react";

import { useClearRefinements } from "react-instantsearch";

import { useVirtualRefinementList } from "~sections/AlgoliaSearch/useVirtualRefinementList";

import type { ClearRefinementsRenderState } from "instantsearch.js/es/connectors/clear-refinements/connectClearRefinements";
import type { RefinementListRenderState } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
import type { ReactNode } from "react";

const AVAILABLE_REFINEMENTS = {
  OFFICE: "location",
  DEPARTMENT: "teamName",
} as const;

export interface JobSearchContextProvided {
  officeRefinementListProps?: RefinementListRenderState;
  departmentRefinementListProps?: RefinementListRenderState;
  clearOfficeRefinementList: ClearRefinementsRenderState["refine"];
  clearDepartmentRefinementList: ClearRefinementsRenderState["refine"];
}

export interface JobSearchContextProviderProps {
  children: ReactNode;
}

const JobSearchContext = createContext<JobSearchContextProvided | null>(null);

export function useJobSearchContext() {
  const context = useContext(JobSearchContext);

  if (!context) {
    throw new Error(
      "useJobSearchContext used outside of a JobSearchContextProvider"
    );
  }

  return context;
}

export function JobSearchContextProvider({
  children,
}: JobSearchContextProviderProps) {
  /**
   * INSTANTIATE "VIRTUAL" REFINEMENT LISTS
   *
   * Because we may be mounting/unmounting refinement lists in a dialog,
   * the refinement state will be lost on an unmount. As a workaround,
   * we invoke the `useRefinementList` hook outside the scope of the refinement list
   * UI component, destructure the API methods, and pass those to the components via a context provoker.
   *
   * This approach recommended by React InstantSearch maintainer here:
   * https://github.com/algolia/react-instantsearch/issues/3542#issuecomment-1170086928
   */
  const officeRefinementListProps = useVirtualRefinementList({
    attribute: "location",
    limit: 99,
  });

  const { refine: clearOfficeRefinementList } = useClearRefinements({
    includedAttributes: [AVAILABLE_REFINEMENTS.OFFICE],
  });

  const departmentRefinementListProps = useVirtualRefinementList({
    attribute: "teamName",
    limit: 99,
  });

  const { refine: clearDepartmentRefinementList } = useClearRefinements({
    includedAttributes: [AVAILABLE_REFINEMENTS.DEPARTMENT],
  });

  const value = useMemo(() => {
    return {
      officeRefinementListProps,
      departmentRefinementListProps,
      clearOfficeRefinementList,
      clearDepartmentRefinementList,
    };
  }, [
    clearDepartmentRefinementList,
    clearOfficeRefinementList,
    departmentRefinementListProps,
    officeRefinementListProps,
  ]);

  return (
    <JobSearchContext.Provider value={value}>
      {children}
    </JobSearchContext.Provider>
  );
}
