import { createInternalLink } from "~utils/link_utils/createInternalLink";

import type { StoryblokFieldLink } from "~types/storyblok.types";

export interface TOCNavLinkShape {
  href: string | null | undefined;
  text: string | null | undefined;
  type: string;
  id: string;
}

interface TransformAnchorsToTableOfContentsArgs {
  elements?: Array<HTMLElement>;
}

export interface HeadingObject {
  title: string;
  childHeadings: Array<HeadingObject>;
  link?: StoryblokFieldLink;
  id: string;
}

/**
 * Callback to aid in transforming anchored headings into a nested array of objects
 */
const reducerGroupHeadings = (
  accumulator?: Array<HeadingObject>,
  heading?: TOCNavLinkShape
) => {
  const { href, text, type, id } = heading || {};

  if (!id) return undefined;

  if (href && text && type) {
    switch (type) {
      case "H2":
      case "H3":
        accumulator?.push({
          title: text,
          link: createInternalLink(href),
          childHeadings: [],
          id,
        });
        break;

      case "H4":
      case "H5":
      case "H6":
        if (accumulator && accumulator[accumulator.length - 1]?.childHeadings) {
          accumulator[accumulator.length - 1]?.childHeadings.push({
            title: text,
            link: createInternalLink(href),
            childHeadings: [],
            id,
          });
        }
        break;

      default:
        break;
    }
  }

  return accumulator;
};

/**
 * Get required attributes from list of elements with anchor links (from anchor.js)
 */
export const transformAnchorsToHeadingProps = (
  elements?: Array<HTMLElement>
): Array<TOCNavLinkShape | undefined> => {
  if (!Array.isArray(elements)) return [];

  return elements.map((element: HTMLElement) => {
    const text = element.textContent;
    const type = element.tagName;
    const { id } = element;
    const href = element.querySelector(".anchorjs-link")?.getAttribute("href");

    return {
      href,
      text,
      id,
      type: type || "H3",
    };
  });
};

/**
 * Given an array of html elements that are being handled
 * by anchor.js, transform them into a nested array of
 * objects to use as props for the TableOfContents component
 */
export const transformAnchorsToTableOfContents = ({
  elements,
}: TransformAnchorsToTableOfContentsArgs) => {
  const allHeadings = transformAnchorsToHeadingProps(elements);

  if (!Array.isArray(allHeadings)) return undefined;

  return allHeadings.reduce(reducerGroupHeadings, []);
};
