import React from "react";

import { PhoneMockup } from "../PhoneMockup";
import { Video } from "../Video";

import type { VideoProps } from "../Video";

export interface VideoPhoneMockupProps extends VideoProps {
  hasBackground?: boolean;
}

export function VideoPhoneMockup({
  video,
  poster,

  ...rest
}: VideoPhoneMockupProps) {
  return (
    <PhoneMockup display="flex" justifyContent="center" {...rest}>
      <Video video={video} poster={poster} />
    </PhoneMockup>
  );
}
