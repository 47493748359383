import React from "react";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { TickListItem } from "./_TickListItem";

import type { TickListItemProps } from "./_TickListItem";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface TickListProps extends GetSprinklesArgs, StoryblokBlok {
  items?: Array<TickListItemProps>;
}

/**
 * ------------------------------------------------------------------------------
 * Renders a list of items with a tick icon
 * ------------------------------------------------------------------------------
 */

export function TickList({ items, ...rest }: TickListProps) {
  return (
    <Box as="ul" marginY="spacing3" padding="none" {...rest}>
      {items &&
        items.map((item) => {
          return (
            <StoryblokEditable key={item._uid} {...item}>
              <TickListItem {...item} key={item._uid} />
            </StoryblokEditable>
          );
        })}
    </Box>
  );
}
