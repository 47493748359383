import * as React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { AccordionBase } from "~components/AccordionBase";

import { LayoutSidebar } from "~sections/layout/LayoutSidebar";

import { AnchorJsContext } from "~context/AnchorJsContext";

import { TableOfContentsItem } from "./_TableOfContentsItem";
import { transformAnchorsToTableOfContents } from "./_transformAnchorsToTableOfContents";

import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

const TITLE_TEXT = "On this page";

/**
 * Works on pages that have anchored headings (provided by anchor.js)
 * - Reads anchored elements provided by {@link AnchorJsContext}
 * - Renders a sidebar section with a list of headings
 */
export function TableOfContents({ ...rest }: GetSprinklesArgs) {
  const { elements, activeId } = React.useContext(AnchorJsContext) || {};

  const [activeAccordionValue, setActiveAccordionValue] =
    React.useState<string>();

  const groupedHeadings = React.useMemo(() => {
    return transformAnchorsToTableOfContents({ elements });
  }, [elements]);

  React.useEffect(() => {
    const Ids = groupedHeadings?.map((heading) => {
      return heading.id;
    });

    if (activeId && Ids?.includes(activeId)) {
      setActiveAccordionValue(activeId);
    }
  }, [activeId, groupedHeadings]);

  if (!Array.isArray(elements) || elements.length < 2) return null;

  return (
    <LayoutSidebar.Section
      padding="spacing3"
      className={variantBackgroundColor.background_sand}
      borderRadius="sm"
      title={TITLE_TEXT}
    >
      <AccordionBase
        collapsible
        defaultValue="0"
        value={activeAccordionValue}
        {...rest}
      >
        {groupedHeadings?.map((item) => {
          return <TableOfContentsItem key={item.id} {...item} />;
        })}
      </AccordionBase>
    </LayoutSidebar.Section>
  );
}
