import React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { Link } from "~components/Link";

import { linkStyle } from "./styles.css";

import type { ReactNode } from "react";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { StoryblokFieldLink } from "~types/storyblok.types";

interface ContentsLinkProps extends GetSprinklesArgs {
  children: ReactNode;
  link: StoryblokFieldLink;
  isActive?: boolean;
  className?: string;
}

export function ContentsLink({
  children,
  link,
  isActive,
  className: userClassName,
  ...rest
}: ContentsLinkProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  return (
    <Link
      link={link}
      data-active={isActive ? "true" : undefined}
      className={clsx(userClassName, linkStyle, getSprinkles(atomProps))}
      {...otherProps}
    >
      {children}
    </Link>
  );
}
