import { useCallback, useReducer } from "react";

import { sectionScrollReducer } from "./_sectionScrollReducer";

export interface AccordionState {
  current: number | undefined;
  previous: number | undefined;
}

/**
 * ------------------------------------------------------------------------------
 * Hook responsible for handling the scroll animation and updating the active
 * accordion item index.
 * ------------------------------------------------------------------------------
 */

interface UseSectionScrollProgressArgs {
  accordionLength: number;
}

export const useSectionScrollProgress = ({
  accordionLength,
}: UseSectionScrollProgressArgs) => {
  const [state, dispatch] = useReducer(sectionScrollReducer, {
    accordionLength,
  });

  const handleUserSetState = useCallback(
    (stringifiedIndex: `${number}`) => {
      const index = Number(stringifiedIndex);

      dispatch({
        type: "HANDLE_SET_INDEX",
        payload: { newIndex: index },
      });
    },

    []
  );

  return {
    handleUserSetState,
    accordionState: {
      current: state.activeIndex,
      previous: state.previousIndex,
    },
  };
};
