import React from "react";

import { Box } from "~components/Box";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

interface TableCell {
  _uid: string;
  value: string;
}
type TableRow = TableCell[];

type TableBody = Array<{
  _uid: string;
  body: TableRow;
}>;

export interface TableProps extends StoryblokBlok, BoxProps {
  table?: StoryblokBlok & {
    tbody: TableBody;
    thead: TableRow;
  };
}

/** Renders a html table from  output of storyblok table field plugin. */
export function Table({ table, ...rest }: TableProps) {
  if (!table) return null;

  return (
    <Box as="table" {...rest}>
      {table.thead && table.thead.length > 0 && (
        <thead>
          <tr>
            {table.thead.map((item) => {
              return <th key={item.value}>{item.value}</th>;
            })}
          </tr>
        </thead>
      )}
      {table.tbody && table.tbody.length > 0 && (
        <tbody>
          {table.tbody.map((row) => {
            return (
              <tr key={row._uid}>
                {row.body.map((column, index) => {
                  if (index === 0) {
                    return <td key={column.value}>{column.value}</td>;
                  }

                  return <td key={column._uid}>{column.value}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      )}
    </Box>
  );
}
