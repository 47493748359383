import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import AnchorJS from "anchor-js";

import { useInViewId } from "./useInViewId";

import type { ReactNode } from "react";
import type { Anchor } from "~types/anchor-js.types";

export interface AnchorJsContextProvided {
  elements?: Array<HTMLElement>;
  activeId?: string;
}

export interface AnchorJsContextProviderProps {
  children: ReactNode;
}

const AnchorJsContext = createContext<AnchorJsContextProvided>({});

function AnchorJsContextProvider({ children }: AnchorJsContextProviderProps) {
  const [elements, setElements] = useState<Array<HTMLElement>>([]);

  const anchorInstance = useRef<Anchor>();

  const activeId = useInViewId({ elements });

  useEffect(() => {
    if (typeof window !== "undefined" && !anchorInstance.current) {
      anchorInstance.current = new AnchorJS();
    }
  }, []);

  useEffect(() => {
    if (anchorInstance.current) {
      anchorInstance.current.options = {
        placement: "left",
      };

      anchorInstance.current.add(`.anchored :is(h2,h3,h4,h5,h6)`);

      if (anchorInstance.current.elements) {
        setElements(anchorInstance.current.elements);
      }
    }

    return () => {
      return anchorInstance.current?.removeAll();
    };
  }, []);

  const value = useMemo(() => {
    return { elements, activeId };
  }, [activeId, elements]);

  return (
    <AnchorJsContext.Provider value={value}>
      <div className="anchored">{children}</div>
    </AnchorJsContext.Provider>
  );
}

export { AnchorJsContext, AnchorJsContextProvider };
