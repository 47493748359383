import React from "react";

import { faPlayCircle } from "@fortawesome/sharp-regular-svg-icons/faPlayCircle";

import { IconFontAwesome } from "~components/IconFontAwesome";

import * as styles from "./styles.css";

export interface VideoPlayButtonProps {
  onClick?(...args: Array<unknown>): unknown;

  isPlaying?: boolean;
}

export function VideoPlayButton({ isPlaying, onClick }: VideoPlayButtonProps) {
  if (!isPlaying)
    return (
      <button type="button" onClick={onClick} aria-label="play">
        <IconFontAwesome
          icon={faPlayCircle}
          className={styles.playButtonStyle}
        />
      </button>
    );

  return null;
}
