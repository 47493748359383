import React from "react";

import { faCheck } from "@fortawesome/sharp-regular-svg-icons/faCheck";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface TickListItemProps extends StoryblokBlok, BoxProps {
  text?: string;
  isDisabled?: boolean;
}

export function TickListItem({ text, isDisabled, ...rest }: TickListItemProps) {
  return (
    <Box
      as="li"
      display="flex"
      alignItems="start"
      gap="spacing1"
      marginX="none"
      paddingX="none"
      color={isDisabled ? "text_lowContrast" : "text_highContrast"}
      {...rest}
    >
      <IconFontAwesome
        size="xs"
        borderRadius="50%"
        color="semantic_green"
        marginTop="spacing0"
        padding="spacing0"
        icon={faCheck}
      />
      {text}
    </Box>
  );
}
